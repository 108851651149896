import Router, { useRouter } from 'next/router'
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { DEFAULT_ZOOM } from '../constants'
import { SizeFilter, SortBy } from '../types/graphql'
import { IQueryParams, RedirectUrls } from '../types/types'

type IFilterContext = {
  isReady: boolean
  filters: IQueryParams
  updateParams: (args: Partial<IQueryParams>) => void
  filtersOpen: boolean
  setFiltersOpen: (open: boolean) => void
}

export const FilterContext = createContext<IFilterContext>({
  isReady: false,
  filters: {
    search: undefined,
    placeId: undefined,
    description: undefined,
    lat: undefined,
    lng: undefined,
    zoom: undefined,
    sortBy: undefined,
    minPrice: 0,
    maxPrice: 0,
    canStoreVehicle: undefined,
    size: undefined,
    page: 1,
    staticPage: undefined
  },
  updateParams: () => {},
  filtersOpen: false,
  setFiltersOpen: () => {}
})

const FilterProvider: React.FC<{
  children: React.ReactNode
  initialFilters?: Partial<IQueryParams>
}> = ({ children, initialFilters }) => {
  const value = useFilters(initialFilters)

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}

export default React.memo(FilterProvider)

export const useFilterProvider = (): IFilterContext => useContext(FilterContext)

/**
 * Hook
 */

const useFilters = (initialFilters?: Partial<IQueryParams>): IFilterContext => {
  const router = useRouter()

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)

  const updateParams = useCallback(
    (params: Partial<IQueryParams>): void => {
      const newParams = { ...initialFilters, ...router.query, ...params }
      const filteredParams = Object.entries(newParams).reduce(
        (
          memo: { [key: string]: string | number | [number, number] | boolean },
          [key, value]
        ) => {
          if (value !== undefined) {
            memo[key] = value
          }

          return memo
        },
        {}
      )

      Router.push({
        pathname: RedirectUrls.Search,
        query: filteredParams
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.query]
  )

  const filters = useMemo<IQueryParams>(() => {
    const {
      search,
      placeId,
      description,
      lat,
      lng,
      zoom,
      sortBy,
      canStoreVehicle,
      size,
      maxPrice,
      minPrice,
      page,
      staticPage
    } = router.query

    return {
      search: toStr(search),
      placeId: toStr(placeId),
      description: toStr(description),
      lat: toNum(lat),
      lng: toNum(lng),
      zoom: toNum(zoom) ?? DEFAULT_ZOOM,
      sortBy: toEnum<SortBy>(sortBy),
      maxPrice: toNum(maxPrice),
      minPrice: toNum(minPrice),
      canStoreVehicle: toBool(canStoreVehicle),
      size: toEnum<SizeFilter>(size),
      page: toNum(page) ?? 1,
      staticPage: toBool(staticPage),
      ...initialFilters
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  const value = useMemo(
    (): IFilterContext => ({
      isReady: router.isReady,
      filters,
      updateParams,
      filtersOpen,
      setFiltersOpen
    }),
    [filters, updateParams, filtersOpen, router.isReady]
  )

  return value
}

/**
 * Helpers
 */

function toStr(arg: string | string[] | undefined): string | undefined {
  if (typeof arg === 'string') return arg
  return undefined
}

function toEnum<T>(arg: string | string[] | undefined): T | undefined {
  if (typeof arg === 'string') return arg as unknown as T
  return undefined
}

function toNum(arg: string | string[] | undefined): number | undefined {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (typeof arg === 'string' && !isNaN(arg as any)) return Number(arg)
  return undefined
}

function toBool(arg: string | string[] | undefined): boolean | undefined {
  if (typeof arg === 'string') {
    return arg === 'true' ? true : arg === 'false' ? false : undefined
  }
  return undefined
}
