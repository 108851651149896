import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { InputMaybe, LocationType, Maybe, SizeFilter } from 'src/types/graphql'
import { mapCanStoreVehicleToRadio } from 'src/utils'

const SIMPLIFIED: { [key in LocationType]: LocationType } = {
  [LocationType.Warehouse]: LocationType.Warehouse,
  [LocationType.Parking]: LocationType.Parking,
  [LocationType.Room]: LocationType.SelfStorageUnit,
  [LocationType.Local]: LocationType.SelfStorageUnit,
  [LocationType.Land]: LocationType.Land,
  [LocationType.Yard]: LocationType.SelfStorageUnit,
  [LocationType.Terrace]: LocationType.SelfStorageUnit,
  [LocationType.Shed]: LocationType.SelfStorageUnit,
  [LocationType.ShippingContainer]: LocationType.SelfStorageUnit,
  [LocationType.SelfStorageUnit]: LocationType.SelfStorageUnit,
  [LocationType.Closet]: LocationType.SelfStorageUnit,
  [LocationType.Basement]: LocationType.SelfStorageUnit,
  [LocationType.Garage]: LocationType.Parking,
  [LocationType.Attic]: LocationType.SelfStorageUnit,
  [LocationType.Other]: LocationType.SelfStorageUnit,
  [LocationType.House]: LocationType.SelfStorageUnit,
  [LocationType.MiniWinery]: LocationType.SelfStorageUnit,
  [LocationType.Penthouse]: LocationType.SelfStorageUnit
}

export default function useCommon() {
  const { t } = useTranslation('common')
  const locMap = t('LOCATION_TYPE', { returnObjects: true })
  const vehicleMap = t('CAN_STORE_VEHICLE', { returnObjects: true })
  const sizeMap = t('SIZE_FILTER', { returnObjects: true })

  const value = useMemo(() => {
    return {
      locMapValues: locMap,
      locMap: (locationType: Maybe<LocationType> | undefined) =>
        locationType ? locMap[SIMPLIFIED[locationType]] : '',
      nonSimplifiedLocMap: (locationType: Maybe<LocationType> | undefined) =>
        locationType ? locMap[locationType] : '',

      vehicleMapValues: vehicleMap,
      vehicleMap: (canStoreVehicle: InputMaybe<boolean> | undefined) =>
        vehicleMap[mapCanStoreVehicleToRadio(canStoreVehicle)],

      sizeMapValues: sizeMap,
      sizeMap: (size: Maybe<SizeFilter> | undefined) =>
        size ? sizeMap[size] : ''
    }
  }, [locMap, vehicleMap, sizeMap])

  return value
}
