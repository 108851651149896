import Avatar from '@mui/material/Avatar'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React from 'react'
import { useFilterProvider } from 'src/context/FilterProvider'
import useLogger from 'src/context/LoggerProvider/useLogger'
import useBreakpoints from 'src/hooks/useBreakpoints'
import useCommon from 'src/hooks/useCommon'
import { getCommercialProfile } from 'src/utils/commercialProfile'
import { getCoverPhotoUrl } from 'src/utils/coverPhoto'
import { Spacing } from '../../constants/theme'
import { ILocation, PropertyType } from '../../types/graphql'
import { displayAddress, numberWithCommas } from '../../utils'
import CardLocationLink from '../CardLinks/CardLocationLink'

interface SpotCardProps {
  location: ILocation
  styleDefault?: boolean
  toggleLocation?: (location: ILocation | undefined) => void
}

const SpotCard = ({
  location,
  toggleLocation,
  styleDefault = true
}: SpotCardProps) => {
  const { t } = useTranslation('common')
  const { isUpMd } = useBreakpoints()
  const user = getCommercialProfile(location.user)
  const {
    urlRoute,
    title,
    locationType,
    price,
    size,
    shortAddress,
    bussinessProfile,
    propertyType
  } = location
  const { firstname, lastname, profilePicture } = user ?? {}
  const { locMap } = useCommon()
  const locType = locMap(locationType)
  const logger = useLogger()

  const displayLastname = lastname
    ? lastname.length > 2
      ? `${lastname?.charAt(0)}.`
      : lastname
    : ''
  const { name, pp } =
    propertyType === PropertyType.Commercial && bussinessProfile
      ? { name: bussinessProfile?.name, pp: bussinessProfile?.profilePicture }
      : { name: firstname + ' ' + displayLastname, pp: profilePicture }

  const toggle = toggleLocation && isUpMd ? toggleLocation : () => {}

  const { filters } = useFilterProvider()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const entries = Object.entries(filters).filter(([, v]) => !!v) as any
  const params = new URLSearchParams(entries)
  const url = isUpMd ? (urlRoute ?? '') : `${urlRoute}?${params.toString()}`

  return (
    <div
      className={`${styleDefault ? 'location-search-card' : 'location-search-card min-w-64 max-w-64 md:min-w-80 md:max-w-80'} relative flex flex-col`}
      onMouseEnter={() => toggle(location)}
      onMouseLeave={() => toggle(undefined)}
    >
      <CardLocationLink
        urlRoute={url}
        // eslint-disable-next-line react/no-children-prop
        children="View location details"
      />
      <div
        className={`relative w-full overflow-hidden rounded-2xl ${styleDefault ? 'aspect-[2/1] md:aspect-[100/66]' : 'aspect-[130/87] md:aspect-[160/87]'}`}
        key={title}
      >
        <div className="absolute -top-1 right-0 z-10 flex flex-row rounded-br-xl">
          <span className="mt-2 text-sm font-bold text-white-500">{name}</span>
          <Avatar
            id="avatar"
            sx={{
              width: 35,
              height: 35,
              marginLeft: Spacing.xSmall,
              marginTop: '3px',
              marginRight: '-1px'
            }}
          >
            <Image
              alt={firstname ?? ''}
              src={pp?.url ?? '/images/avatar.png'}
              fill
              sizes="(max-width: 428px) 10vw,
              (max-width: 768px) 5vw,
              (max-width: 1200px) 5vw,
              5vw"
              style={{ objectFit: 'cover' }}
              onError={(e) => logger.error('Error loading img', e)}
            />
          </Avatar>
        </div>
        <Image
          src={getCoverPhotoUrl(location)}
          alt="service"
          fill
          sizes="(max-width: 428px) 45vw,
          (max-width: 768px) 45vw,
          (max-width: 1200px) 18vw,
          18vw"
          style={{ objectFit: 'cover' }}
          onError={(e) => logger.error('Error loading img', e)}
        />
      </div>
      <div className="whitespace-nowrap px-2 pt-1 text-black-400">
        <div className="flex w-full flex-row font-semibold">
          <p className="overflow-hidden text-ellipsis">
            {locType ? `${locType}` : ''}
          </p>
          <div className="ml-auto">
            <p>
              {size?.length} x {size?.width} m<sup>2</sup>
            </p>
          </div>
        </div>
        <div className="flex w-full flex-row md:flex-col">
          <p className="overflow-hidden text-ellipsis">
            {displayAddress(shortAddress)}
          </p>
          <div className="ml-auto md:ml-0">
            <p className="font-bold text-blue-500">
              ${numberWithCommas(price)} /{t('FILTER.MONTH')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SpotCard)
